import { render, staticRenderFns } from "./About.vue?vue&type=template&id=4cdbff68&scoped=true&"
import script from "./About.vue?vue&type=script&lang=js&"
export * from "./About.vue?vue&type=script&lang=js&"
import style0 from "../assets/css/About.css?vue&type=style&index=0&id=4cdbff68&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cdbff68",
  null
  
)

export default component.exports